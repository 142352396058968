// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;
import "bootstrap";

$( document ).on('turbolinks:load',function () {
    $(".toast").toast('show');
    if ($(window).width() < 576 && $('.toast').length === 0 &&  $('#scroll-on-ready').length > 0){
        // Handler for .ready() called.
        $('html, body').animate({
            scrollTop: $('#scroll-on-ready').offset().top-10
        }, 'slow');
    }
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
